<template>
  <div class="page-wrap seat">
    <div class="top-bar">
      <mtop />
    </div>
    <div class="banner">
      <div class="inner">
        <div class="left">
          <div class="title">坐席助手</div>
          <div class="desc">通过实时理解坐席和客户对话，在通话的不同阶段为坐席提供智能辅助， 标准化座席接待流程，规范座席行为，大幅提升座席接待效率， 实现“人+机” 无缝协同</div>
          <div class="btn" @click="dialogVisible = true, clickMenu('免费试用')">免费试用</div>
        </div>
        <div class="right bg-right"></div>
      </div>
    </div>

    <!-- 产品功能 -->
    <div class="section bg-white func">
      <div class="title">产品功能</div>
      <div class="content">
        <div class="list">
          <div class="item">
            <div class="text-info" style="width: 466px;">
              <div class="title">话术流程导航</div>
              <div class="desc">通过对通话内容和已有引导流程进行智能算法匹配，显示当前对话流程完成情况，提示坐席下一步话术。支持根据对话内容匹配话术流程，可灵活设置话术内容。确保服务流程的规范性和一致性，提高对外服务的统一性和专业性。</div>
            </div>
            <img src="@/assets/seat/fun-1.png" style="width: 636px;height: 353px" />
          </div>
          <div class="item">
            <img src="@/assets/seat/fun-2.png" style="width: 636px;height: 353px" />
            <div class="text-info" style="width: 485px;">
              <div class="title">实时对话知识推荐</div>
              <div class="desc">可自动抓取客户咨询的常见问题标签，并快速推荐最佳回答话术，坐席也可以通过关键词搜索，查找相关知识点，快速回答客户问题，<span class="c-blue">提高解决问题的效率，确保信息的准确性。</span></div>
            </div>
          </div>
          <div class="item">
            <div class="text-info" style="width: 466px;">
              <div class="title">自动提取客户画像标签</div>
              <div class="desc">基于对话内容自动提取客户标签，丰富用户画像。有助于企业更全面地了解客户需求和行为特征，客服能够提供更有针对性的产品推荐或服务；支持查看历史联络记录掌握客户全部来访记录诉求，提升赢单率</div>
            </div>
            <img src="@/assets/seat/fun-3.png" style="width: 630px;height: 389px" />
          </div>
        </div>
      </div>
    </div>
    <!-- 产品优势 -->
    <div class="section advantage">
      <div class="title">产品优势</div>
      <div class="content">
        <div class="list">
          <div class="item" v-for="(item, index) in advantageList" :key="index">
            <div class="icon">
              <img :src="getIcon(item)" alt="" />
            </div>
            <div class="info">
              <div class="title">{{item.name}}</div>
              <div class="desc">{{item.content}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 应用场景 -->
    <div class="section bg-white scene">
      <div class="title">应用场景</div>
      <div class="content">
        <div class="list">
          <div class="item" v-for="(item, index) in sceneList" :key="index">
            <div class="icon">
              <img :src="getIcon(item)" alt="" />
            </div>
            <div class="info">
              <div class="title">{{item.name}}</div>
              <div class="desc">{{item.content}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 客户案例 -->
    <div class="section case">
      <div class="title">客户案例</div>
      <div class="content">
        <div class="detail-panel">
          <div class="left"></div>
          <div class="right">
            <div class="top-info">
              <div class="title">某保险公司坐席助手项目</div>
              <div class="sub-tit">客户联络中心人工智能应用系统</div>
            </div>
            <div class="info-panel">
              <div class="item">
                <div class="title">
                  <img class="icon" src="@/assets/seat/icon-xq.png">
                  项目需求
                </div>
                <div class="desc">
                  1.为电话客服服务过程提供智能化辅助； <br/>
                  2.对通话录音进行转写和信息挖掘；<br/>
                  3.建设统一Al能力平台，以API形式为其 他系统提供人工智能能力支撑。
                </div>
              </div>
              <div class="item">
                <div class="title">
                  <img class="icon" src="@/assets/seat/icon-value.png">
                  项目价值
                </div>
                <div class="desc">
                  服务坐席人数：20人+  <br/>
                  知识推荐准确率：80%以上  <br/>
                  话术推荐准确率：80%以上
                </div>
              </div>
            </div>
            <div class="info-panel" style="margin-top: 20px">
              <div class="item" style="height: auto">
                <div class="title">
                  <img class="icon" src="@/assets/seat/icon-js.png">
                  建设内容
                </div>
                <div class="desc">
                  坐席辅助：以”人＋机”的方式实现知识推荐服务<br/>
                  机器学习：通过事后机器学习不断积淀数据，持续优化辅助效果<br/>
                  能力平台：搭建语义理解、语音识别、深度学习引擎
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 合作伙伴 -->
    <partner/>

    <fotterBanner></fotterBanner>
    <mbottom />
    <demonstrate
        :dialogVisible="dialogVisible"
        @closeDialog="dialogVisible = false"
    ></demonstrate>
  </div>
</template>

<script>
import mtop from "@/components/top.vue";
import Partner from "@/components/partner";
import mbottom from "@/components/bottom.vue";
import fotterBanner from "@/components/fotterBanner.vue";
import demonstrate from "@/components/demonstrate.vue";
import {staClickMenu} from "@/utils";

export default {
  components: {
    Partner,
    mtop,
    mbottom,
    fotterBanner,
    demonstrate
  },
  data() {
    return {
      dialogVisible: false,
      advantageList: [
        {
          name: '知识智能推荐',
          content: '可根据实时通话内容进行知识推荐，推荐的知识由系统知识中台统一运营维护，提高坐席服务能力和水平。',
          icon: 'icon-adv-1.png'
        },
        {
          name: '智能标签提取',
          content: '根据业务场景需要，当会话中出现标签要素内容时，可自动提取标签并关联客户信息，用于后续营销动作。',
          icon: 'icon-adv-2.png'
        },
        {
          name: '实时质检',
          content: '在会话过程中可实时进行合规质检，规避坐席被投诉风险，确保服务过程中的合法合规性，提高客户满意度。',
          icon: 'icon-adv-3.png'
        },
        {
          name: '数据运营分析',
          content: '根据客户的问题、回答、服务等数据进行分析和统计，为企业提供精准的数据决策支持。优化服务流程，提高客户忠诚度。',
          icon: 'icon-adv-4.png'
        }
      ],
      sceneList: [
        {
          name: '外呼回访',
          content: '在外呼回访过程中，实现自动填写工单要素信息，自动小结并生成用户画像等功能。',
          icon: 'icon-scene-1.png'
        },
        {
          name: '电话营销',
          content: '坐席助手通过实时转写对话过程，理解客户意图，实时界面提示最优话术，提升坐席话术技巧，辅助提高成单率。',
          icon: 'icon-scene-2.png'
        },
        {
          name: '售后服务',
          content: '对会话客户问题咨询处理，有效解决产品咨询、投诉意见、故障报修等问题',
          icon: 'icon-scene-3.png'
        },
        {
          name: '客情分析',
          content: '根据会话数据进行智能提取要素，同时可根据数据标签对客户意图做分析，更有针对性做企业策略优化',
          icon: 'icon-scene-4.png'
        }
      ],
      timeList: [
        {
          name: '开始时间',
          content: `2020年7月项目启动`,
        },
        {
          name: '完成动作',
          content: `需求调研、需求跟踪、原型设计需求沟通`,
        },
        {
          name: '结束时间',
          content: `2020年12月中`,
        },
        {
          name: '完成动作',
          content: `开发测试、系统交付、运营培训`,
        }
      ]
    }
  },
  methods: {
    getIcon(item) {
      return require(`@/assets/seat/${item.icon}`)
    },
    clickMenu(menuName, url) {
      if (url) {
        this.$router.replace({
          path: url,
        });
      } else {
        if (menuName != '免费试用') {
          this.$toast("敬请期待");
        }
      }
      staClickMenu(menuName)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
